.App {
    text-align: center;
    height: 100%;
}

#root {
    height: 100%;
}

.wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.pulse-active {
    animation: activePulse 1s infinite;
    animation-timing-function: ease-in-out;
}

@keyframes activePulse {
    0% {
        background-color: #fff;
    }

    50% {
        background-color: #8bbfe6;
    }

    100% {
        background-color: #fff;
    }
}

.ui.main-topbar {
    height: 95px;
    background-color: #14375a;
    padding: 10px !important;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    border-bottom: 1px solid lightgrey;
}


.sideBar ::-webkit-scrollbar-thumb {
    border-radius: 0 !important;
}

.main {
    display: flex;
    height: 100%;
}

.headerbar {
    width: 100%;
    height: 100px;
    padding: 10px 20px 10px 20px;
    border-bottom: 1px solid #e9e9e9;
    color: #333;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-name {
    display: flex;
    align-items: center;
}

.menu {
    margin: 0 !important;
    border-bottom: 1px solid #e9e9e9 !important;
}

.ui.secondary.menu .active.item {
    color: white;
    background-color: #14375a;
    border-radius: 0;
}

.headerbar h2 {
    margin: 0;
    padding-left: 10px;
}

.header-wrapper {
    margin: 0 10%;
}

.header-wrapper>h1 {
    margin-right: 50px;
}

.sideMenu {
    width: 100% !important;
    border: none !important;
    overflow-y: scroll;
    flex: 1;
    margin: 0 !important;
}

.sideBar {
    display: flex;
    flex-direction: column;
    border-right: 1px solid#e9e9e9;
    width: 500px;
    min-width: 240px;
    background-color: #dadada3b;
}

.quicksearch {
    background-color: #e9e9e9;
}

.CompactDateRangerPicker .DateRangePickerInput__showClearDates {
    padding-right: 0
}

.advancedSearch {
    overflow-y: scroll;
    background-color: #e9e9e9;
}

.advancedSearch i {
    padding-left: 10px !important;
}

.advancedSearch .title {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    font-weight: 900;
}

.filterComponent {
    height: 100%;
    padding: 0 !important;
}

.filterTable {
    border: none !important;
    font-weight: 700;
}

.filterTable .filterRow {
    border: none !important;
}

.advancedSearch .filters .ui.dropdown {
    margin-bottom: 10px;
}

.advancedSearch {
    padding: 0px 4px 4px 10px;
}

.advancedSearch .table {
    background-color: #f5f5f5 !important;
}

.advancedSearch .table .dropdown {
    background-color: white !important;
}

.ui.menu {
    min-height: 0;
    border-radius: 0;
}

.ui.vertical.menu .item {
    display: flex;
    padding: 0;
}

.ui.accordion .title.ticketlist {
    padding: 0.5em 0 !important;
    font-weight: bold;
    text-align: center;
    padding: 0;
    color: white;
    background-color: #015085;
    display: flex;
    align-self: center;
    justify-content: space-between;
    padding-right: 10px !important;
    padding-left: 20px !important;
}

.ui.accordion .content {
    padding: 0 !important;
    background-color: white;
}

.detailView {
    height: 100%;
    width: 100%;
}

.ticket-link {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    background-color: #f5f5f5;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 100 !important;
}

.ticket-link:hover,
.ticket-link-active {
    color: black !important;
    background-color: #e7e6e6;
}

.ticket-status {
    background-color: #f5f5f5;
}

.overdue {
    background-color: #ff6400;
    width: 6px;
}

.ticket-link h4 {
    margin-bottom: 10px;
}

.ticket-meta {
    display: flex;
    justify-content: space-between;
}

.ticket-current-agent {
    align-self: flex-end;
}

.ui.label.product {
    background-color: rgb(206, 203, 203);
}

.ui.label.aspaCase {
    color: white;
    background-color: #14375a;
}

.ui.label.kiireinen {
    color: white;
    background-color: rgb(255, 166, 0);
}

.ui.label.sähköposti {
    display: none;
}

.ui.label.feedback {
    color: white;
    background-color: #14375a;
}

.ui.label.idea {
    color: white;
    background-color: #14375a;
}

.ui.label.question {
    color: white;
    background-color: #14375a;
}

.center-area {
    display: flex !important;
    flex-direction: column !important;
    height: calc(100vh - 250px);
}

.center-tabs {
    display: flex;
    flex-direction: column;
}

.ui.tab {
    overflow-y: scroll;
}

.renewLoginWrapper {
    widows: 100%;
    height: 100vh;
    background: rgb(20, 55, 90, 0.5);
    display: flex;
    justify-content: center;
}

.renewLoginForm {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.loginWrapper {
    widows: 100%;
    height: 100vh;
    background: #14375a;
    display: flex;
    justify-content: center;
}

.loginForm {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.customerCard {
    display: flex;
    justify-content: space-between;
}
